import * as React from "react"
import { graphql,Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const EntryformSent = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お問い合わせ｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-mail bg-no-repeat bg-center bg-cover z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl pt-10 pb-20">Entry</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[800px] h-screen mx-auto py-10 sm:py-10">

            <section id="ContactUs" className="mb-20">
            <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-10 sm:mb-20 py-3 ">
              ご応募ありがとうございました
            </h2>
            <p>
            この度は、弊社求人にご応募いただきまして誠に有難うございます。 <br />
            <br />
            お送りいただいた応募資料をもとに書類選考をさせていただきます。<br />
            1週間以内に選考結果について合否に関わらずご連絡いたしますので<br />
            今しばらくお待ちいただきますようお願いいたします。<br />
            <br />
            １週間経っても弊社から連絡がない場合は、お手数ではございますが<br />
            ホームページの「Contact US」からお問い合わせください。<br />
            <br />
            なお、採用について何かご不明な点等ございましたら、<br />
            弊社採用担当までご遠慮なくお問い合わせ下さい。<br />
            </p>
            <Link to="/" class="my-10 sm:my-20 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className=" ">Homeへ</span>
            </Link>
            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default EntryformSent

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`